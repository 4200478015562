// Header.tsx

import React, { useState, useEffect } from "react";
import { ReactComponent as IconLogo } from "./assets/icons/svg/logo.svg";
import { ReactComponent as DocsIcon } from "./assets/icons/svg/docs.svg";
import { ReactComponent as SupportIcon } from "./assets/icons/svg/support.svg";
import { ReactComponent as TelegramIcon } from "./assets/icons/svg/telegram.svg";
import MarginCurrencies from "./MarginCurrencies";
import Balances from "./Balances";
import Wallets from "./Wallets";
import { eventCloud } from "./EventCloud";
import { News } from "./interfaces/news.interface";
import "./css/Header.css";
import classNames from "classnames";
import { Tooltip } from "./components/Tooltip";
import { Button } from "./components/Button/Button";
import { FaqPopup } from "./components/FaqPopup/FaqPopup";


interface HeaderProps {
  isLiteVersion: boolean;
  setLiteVersion: (isLite: boolean) => void;
}


//export const TVChartContainer: React.FC<ChartProps> = ({ lite }) => {
export const Header: React.FC<HeaderProps> = ({ isLiteVersion, setLiteVersion }) => {
  const [newsStore, setNewsStore] = useState<News[]>([]);
  const [isFaqPopupOpen, setFaqPopupOpen] = useState(false);

  useEffect(() => {
    // Subscribe
    const subscription = eventCloud.newsStore.subscribe((news) => {
      setNewsStore(news);
    });

    // Cleanup
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleFaqPopupClose = () => {
    setFaqPopupOpen(false)
  }

  const handleFaqPopupOpen = () => {
    setFaqPopupOpen(true)
  }

  return (
    <>
      <header
        className={classNames("flex flex-wrap items-center bg-black text-white uppercase", {
          'border-b border-gray-730 header-lite': isLiteVersion
        })}
      >
        <div className="px-2 w-20 h-12 sm:px-4 border-r border-gray-730 sm:w-26 flex justify-center items-center header_logo">
          <a href="/"><IconLogo className="w-16 sm:w-20" /></a>
        </div>



        <a
          href="https://docs.dmex.app/"
          target="_blank"
          className="hidden sm:flex justify-center items-center px-2 h-12 border-r border-gray-730 text-xs text-gray-150 hover:text-white ease-in duration-100 header_link1"
        >

          <Tooltip
            position="left"
            maxWidth="200px"
            minWidth="50px"
            underColor="border-gray-450"
            text="documentation"
          >
            <DocsIcon className="w-7" />
          </Tooltip>
        </a>
        <a
          href="https://dmexhelp.freshdesk.com/support/home"
          target="_blank"
          className="hidden sm:flex justify-center items-center px-2  h-12 border-r border-gray-730 text-xs text-gray-150 hover:text-white ease-in duration-100 header_link2"
        >

          <Tooltip
            position="left"
            maxWidth="200px"
            minWidth="50px"
            underColor="border-gray-450"
            text="support"
          >
            <SupportIcon className="w-7" />
          </Tooltip>
        </a>

        <a
          href="https://t.me/dmex_official"
          target="_blank"
          className="hidden sm:flex justify-center items-center px-2 h-12 border-r border-gray-730 text-xs text-gray-150 hover:text-white ease-in duration-100 header_link3"
        >
           <Tooltip
            position="left"
            maxWidth="200px"
            minWidth="50px"
            underColor="border-gray-450"
            text="telegram"
          >
            <TelegramIcon className="w-7" />
          </Tooltip>
        </a>

        <div className="VersionSwitch-container ml-2 sm:ml-auto min-[993px]:ml-2 border-l  border-t flex bg-black bg-opacity-30 border-b border-gray-730 text-xs text-white">
          <input
            type="radio"
            id="pro"
            name="pro"
            value="pro"
            checked={!isLiteVersion}
            onChange={() => setLiteVersion(false)}
            className="invisible absolute"
          />
          <label
            htmlFor="pro"
            className={classNames(
              "px-2 pt-1 pb-1 box-border border-r flex-1 border-gray-730 cursor-pointer text-center bg-black ",
              {
                "text-black bg-red-550": !isLiteVersion,
              }
            )}
          >
            <div className={classNames("pb-0.5", {
                "text-white font-bold": !isLiteVersion,
              }
              )}
            >PRO</div>
          </label>

          <input
            type="radio"
            id="lite"
            name="lite"
            value="lite"
            checked={isLiteVersion}
            onChange={() => setLiteVersion(true)}
            className="invisible absolute"
          />
          <label
            htmlFor="lite"
            className={classNames(
              "px-2 pt-1 pb-1 box-border border-r flex-1 border-gray-730 cursor-pointer text-center bg-black ",
              {
                "text-white bg-green-550": isLiteVersion,
              }
            )}
          >
            <div className={classNames("pb-0.5", {
                "text-white font-bold": isLiteVersion,
              }
              )}
            >LITE</div>
          </label>

          
        </div>

        <div className="MarginCurrencies-container">
          <MarginCurrencies />
        </div>

        <div className="NoLiquidations-container !pr-2 min-[800px]:!pr-6 ml-2">
          <Button
              variant="gray"
              size="smallx"
              onClick={handleFaqPopupOpen}
            >
              FAQ
          </Button>
        </div>
        {/*<div className="order-7 lg:order-6">
          <span className="text-xs text-gray-450">
            
            <Tooltip
              label="NO MARGIN LIQUIDATION [?]"
              position="right"
              maxWidth="300px"
              minWidth="300px"
              underColor="border-gray-450"
              text="When a position reaches its liquidation price, it is closed at the market price (AUTO STOP-LOSS). Remaining collateral is returned to the user balance."
            />
          </span>
        </div>*/}



        <FaqPopup open={isFaqPopupOpen} onClose={handleFaqPopupClose} />


        <div className="Balances-container ml-auto mr-4">
          <Balances />
        </div>
        <div className={classNames("Wallets-container border-t sm:border-t-0 sm:w-60 sm:border-l border-gray-730 pl-1 pr-2 sm:px-4 text-left h-12 flex items-center", { "h-8 pr-2 sm:pr-3 md:pr-6": isLiteVersion })}>
          <Wallets isLiteVersion={isLiteVersion} />
        </div>
        <div className="header-break-column-1380-wallet" />
        <div className="header-break-column-800" />
      </header>
      {!isLiteVersion && <div className="flex py-1 width-full text-xxs text-yellow-550 bg-gray-750 border-t border-b border-gray-730 overflow-x-hidden news-carousel-wrapper" style={{ height: '26px', minHeight: '26px' }}>
        <div className="news-carousel" style={{ minWidth: `100000px` }}>

          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-730 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span>

              <a  className={classNames("ml-2", [
                news.internal
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-730 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span>

              <a  className={classNames("ml-2", [
                news.internal
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-730 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span>

              <a  className={classNames("ml-2", [
                news.internal
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-730 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span>

              <a  className={classNames("ml-2", [
                news.internal
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-730 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span>

              <a  className={classNames("ml-2", [
                news.internal
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-730 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span>

              <a  className={classNames("ml-2", [
                news.internal
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-730 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span>

              <a  className={classNames("ml-2", [
                news.internal
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-730 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span>

              <a  className={classNames("ml-2", [
                news.internal
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-730 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span>

              <a  className={classNames("ml-2", [
                news.internal
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
          {newsStore.map((news, index) => (
            <div  key={index} className="news-item border-r border-gray-730 shrink-0 px-4 pt-px">
              <span className="text-gray-450">{calculateTimeAgo(news.published_at)}</span>

              <a  className={classNames("ml-2 ", [
                news.internal
                  ? "text-green-550 font-bold"
                  : "text-yellow-550"
              ])} target="_blank" href={news.url}>{news.title}</a>
            </div>
          ))}
        </div>
      </div>}
    </>
  );
};

const calculateTimeAgo = (timestamp: string): string => {
  const currentDate = new Date().getTime(); // Convert to number
  const pastDate = new Date(timestamp).getTime(); // Convert to number

  const timeDifference = currentDate - pastDate;
  const minutes = Math.floor(timeDifference / (1000 * 60));

  if (minutes < 60) {
    // Less than an hour ago
    return(`${minutes}M`);
  } else if (minutes < 24 * 60) {
    // Less than a day ago
    const hours = Math.floor(minutes / 60);
    return(`${hours}H`);
  } else {
    // Days ago
    const days = Math.floor(minutes / (24 * 60));
    return(`${days}D`);
  }
};

